import React, { FunctionComponent } from 'react'
import { Main } from '@vwfs-bronson/bronson-react'
import Header from './Header'
import Footer from './Footer'

export type DefaultLayoutProps = {}

const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

export { DefaultLayout }
