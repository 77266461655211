import React from 'react'
import { ContentSection } from '@vwfs-bronson/bronson-react'
import Heading from './common/Heading'
import BasicSection from './common/BasicSection'
import { scrollToElement } from '../../services/common/form'
import InstructionComponent from '../AppPage/InstructionStepper'
import { MarketingCards } from '../../components/MarketingCards'

const ConfirmationPageDEALERSHOP = ({ storefrontData }) => {
  const originSystem = storefrontData?.salesChannel?.originSystem

  React.useEffect(() => {
    scrollToElement('.c-header')
  }, [])

  const getReceiveAndFileDocumentsTemplateValues = () => {
    return {
      companyName: storefrontData?.dealerData?.companyName,
      email: storefrontData?.dealerData?.contactData?.email,
      phone: `${storefrontData?.dealerData?.contactData?.telephoneCountryCode} ${storefrontData?.dealerData?.contactData?.telephoneNumber}`,
    }
  }

  return (
    <>
      <ContentSection pageWrap>
        <Heading
          originSystem={originSystem}
          stepper={
            <InstructionComponent activeStep={3} originSystem={originSystem} />
          }
        />
        <div className="u-mt-large">
          <BasicSection
            section="receiveAndFileDocuments"
            originSystem={originSystem}
            storefrontData={storefrontData}
            templateValues={getReceiveAndFileDocumentsTemplateValues()}
          />
        </div>
        <div className="u-mt-large">
          <MarketingCards
            manufacturer={storefrontData?.vehicleData?.model?.manufacturer}
            hideMotorInsurance
            storefrontData={storefrontData}
          />
        </div>
      </ContentSection>
    </>
  )
}

export default ConfirmationPageDEALERSHOP
