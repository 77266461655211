import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Button } from '@vwfs-bronson/bronson-react'

class Output {
  static resolve(key, texts, defaultValue) {
    try {
      return key
        .split(/[.[]/)
        .map((k) => k.replace(/]/, ''))
        .reduce((p, c) => p[c], texts)
    } catch (err) {
      console.error(`There is no resource with key ${key}`)
      return defaultValue
    }
  }

  static markdown(v) {
    return (
      <ReactMarkdown
        source={v}
        renderers={{
          link: ({ href, children }) => (
            <Button element="a" href={href} target="_blank">
              {children}
            </Button>
          ),
        }}
        unwrapDisallowed
      />
    )
  }

  static resolveAsMarkdown(key, texts) {
    return Output.markdown(Output.resolve(key, texts))
  }

  static template(text, values) {
    return text.replace(/\${([^}]*)}/, (match, p1) => values[p1])
  }

  static templateWithValues(text, values) {
    let result = text
    const regex = /\${([^}]*)}/g
    const matchAll = text.matchAll(regex)
    Array.from(matchAll).forEach((element) => {
      result = result.replace(element[0], values[element[1]])
    })
    return result
  }
}

const link = Output.markdown
const { resolve } = Output
const generic = Output.markdown
const { template } = Output
const { templateWithValues } = Output

export {
  // Text as Values,
  link,
  resolve,
  generic,
  template,
  templateWithValues,
  Output,
}
