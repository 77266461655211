export const SET_TLS_VALID = 'custom//SET_TLS_VALID'
export const SET_ACTUAL_PAGE = 'custom//SET_ACTUAL_PAGE'
export const SET_ERROR_DATA = 'custom//SET_ERROR_DATA'
export const SET_DISCOUNT = 'custom//SET_DISCOUNT'
export const SET_REDIRECT_URL = 'custom//SET_REDIRECT_URL'

interface SetCustomAction {
  type: typeof SET_TLS_VALID
  payload: any
}

interface SetErrorDataAction {
  type: typeof SET_ERROR_DATA
  payload: any
}

interface SetDiscountAction {
  type: typeof SET_DISCOUNT
  payload: any
}

export type CustomActions =
  | SetCustomAction
  | SetErrorDataAction
  | SetDiscountAction
