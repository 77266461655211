import React from 'react'
import {
  Card,
  Layout,
  Paragraph,
  TileSelect,
} from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

const AdditionalProducts = (props) => {
  const { t } = useTranslation()
  const { additionalProducts, onSelection } = props
  const shouldShowAdditionalProducts = additionalProducts?.find(
    (product) => product.isVisible
  )
  return additionalProducts?.length ? (
    <Card>
      <h4 className="u-text-left">{t('additionalProducts:title')}</h4>
      {t('additionalProducts:texts', { returnObjects: true })?.map(
        (text: string) => (
          <Paragraph className="u-text-left" key={text}>
            {text}
          </Paragraph>
        )
      )}
      {!!shouldShowAdditionalProducts && (
        <Layout center equalHeight>
          {additionalProducts?.map((product: any) =>
            product?.isVisible ? (
              <Layout.Item default="1/3" s="1/1" key={product?.name}>
                <TileSelect
                  id={product?.name}
                  title={product?.name}
                  onClick={() => onSelection(product)}
                  checked={product?.isSelected}
                  disabled={!product?.isSelectable}
                >
                  <Paragraph>{product?.description}</Paragraph>
                  <Paragraph>{product?.disclaimer}</Paragraph>
                </TileSelect>
              </Layout.Item>
            ) : (
              <></>
            )
          )}
        </Layout>
      )}
    </Card>
  ) : (
    <></>
  )
}

export default AdditionalProducts
