import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Button, Layout } from '@vwfs-bronson/bronson-react'
import { isVtiGiS } from '../../services/common/utils'

const marketingManufacturers = [
  'Volkswagen',
  'Volkswagen Nutzfahrzeuge',
  'SEAT',
  'Skoda',
  'Audi',
  'CUPRA',
]

export type MarketingCardsProps = {
  manufacturer: string
  hideMotorInsurance?: boolean
  storefrontData: any
}

export const MarketingCards = (props: MarketingCardsProps) => {
  const { t } = useTranslation()
  const { manufacturer, hideMotorInsurance, storefrontData } = props

  const goToMarketingCardLink = (link) => {
    window.open(link, '_blank')
  }

  const renderCard = (type: string) => {
    return (
      <Layout.Item default="1/2" m="1/2" s="1/1">
        <Card
          title={t(`marketing-cards:${type}:title`)}
          imageSrc={`${process.env.PUBLIC_URL}${t(
            `marketing-cards:${type}:imageUrl`
          )}`}
          small
          subtitle={t(`marketing-cards:${type}:subTitle`)}
          footer
          footerContent={
            <Button
              link
              small
              icon="semantic-forward"
              iconReversed
              onClick={() => {
                goToMarketingCardLink(
                  t(`marketing-cards:${type}:link:${manufacturer}`)
                )
              }}
            >
              {t(`marketing-cards:${type}:linkText`)}
            </Button>
          }
        >
          {t(`marketing-cards:${type}:text`)}
          {isVtiGiS(storefrontData) && type === 'maintenanceAndInspection' && (
            <p className="u-mt-small">{t(`marketing-cards:${type}:text2`)}</p>
          )}
        </Card>
      </Layout.Item>
    )
  }

  return marketingManufacturers.includes(manufacturer) ? (
    <Layout equalHeight center>
      {!hideMotorInsurance && renderCard('motorInsurance')}
      {renderCard('maintenanceAndInspection')}
    </Layout>
  ) : (
    <></>
  )
}
