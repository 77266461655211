import { CustomState } from '../models/CustomState'
import {
  CustomActions,
  SET_TLS_VALID,
  SET_ERROR_DATA,
  SET_DISCOUNT,
} from './actions/custom.actions'

export function setTlsValid(data): CustomActions {
  return {
    type: SET_TLS_VALID,
    payload: data,
  }
}

export function getTlsValid(store) {
  return store.custom.data.tlsValid
}

export function setErrorData(data): CustomActions {
  return {
    type: SET_ERROR_DATA,
    payload: data,
  }
}

export function getErrorData(store) {
  return store.custom.data.errorData
}

const initialState: CustomState = {
  data: {
    tlsValid: false,
    errorData: {},
    discount: undefined,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TLS_VALID:
      return {
        ...state,
        data: { ...state.data, tlsValid: action.payload },
      }
    case SET_ERROR_DATA:
      return {
        ...state,
        data: { ...state.data, errorData: action.payload },
      }
    case SET_DISCOUNT:
      return {
        ...state,
        data: { ...state.data, discount: action.payload },
      }
    default:
      return state
  }
}
