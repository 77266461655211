import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { ContentSection, Spinner } from '@vwfs-bronson/bronson-react'
import { history, qs } from '../../routing'
import routes from '../../../routes'
import { loadStorefrontDataToRedux } from './storefront.service'
import { saveSesionData } from '../../common/utils'

const loadData = (id) => loadStorefrontDataToRedux(id)

const SpinnerSection = () => {
  return (
    <ContentSection pageWrap className="u-m-xlarge">
      <Spinner section />
    </ContentSection>
  )
}

export type WithStorefrontProps = {
  storefrontId: string
  setTokenExpiresAt: Function
}

const WithStorefront: FunctionComponent<WithStorefrontProps> = ({
  storefrontId,
  children,
}) => {
  const [isLoading, setLoading] = React.useState(true)

  useEffect(() => {
    if (storefrontId) {
      saveSesionData({
        transactionId: storefrontId,
        token: qs(document.location.search, 'token'),
      })
      loadData(storefrontId)
        .catch(() => {
          history.pushPreservePath(routes.errorPage)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [storefrontId])

  return (
    <>
      {isLoading && <SpinnerSection />}
      {!isLoading && children}
    </>
  )
}

export default connect(null)(WithStorefront)
